<template>
  <div class="send-list-main">
    <div class="" style="position: relative;height: 88px;">
      <top-bar></top-bar>
    </div>
    <div class="data-all-content">
      <div class="sm-content">
        <p>说明：</p>
        <p>1、请添加要接收问卷的企业名单，企业联系人、手机号码；</p>
        <p>2、如需批量导入企业名单，请 <span class="color-blue" @click="download">下载Excel模板</span>，并确认企业</p>
      </div>
      <div class="load-btn-content flex flex-col-center">
        <div class="btn-item bg-bule flex flex-col-center" @click="importClick">
          <img class="btn-img" src="@/assets/sendQuestionnaire/send_list_export.png" alt=""/>
          <p class="btn-text">批量导入</p>
        </div>
        <div class="btn-item bg-bule-hui flex flex-col-center">
          <img class="btn-img" src="@/assets/sendQuestionnaire/send_list_import.png" alt=""/>
          <p class="btn-text" @click="bulkExport">批量导出</p>
        </div>
        <div class="btn-item bg-greend flex flex-col-center">
          <img class="btn-img" src="@/assets/sendQuestionnaire/send_list_Refresh.png" alt=""/>
          <p class="btn-text" @click="refreshCode">刷新邀请码</p>
        </div>
      </div>
      <div style="width: 1220px;">
        <el-table
            :data="visibleTableData"
            border
            style="width: 100%"
            :header-cell-style="{height:'40px;',background:'#F6F7F9'}"
            :row-style="{height:'60px;', color:'#161B2F',fontSize:'12px', padding:'0'}"
        >
          <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
          <el-table-column prop="company_name" label="企业名称" width="416" align="center">
            <template slot-scope="scope">
              <el-autocomplete
                  class="inline-input"
                  v-model="tableData[scope.row.serial].company"
                  :fetch-suggestions="queryCompanySearch"
                  placeholder="企业名称"
                  :trigger-on-focus="false"
                  style="width: 100%;"
              ></el-autocomplete>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="联系人" width="206" align="center">
            <template slot-scope="scope">
              <input class="tab-input name-input" type="text" placeholder="姓名"
                     v-model="tableData[scope.row.serial].truename"/>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号码" width="236" align="center">
            <template slot-scope="scope">
              <input class="tab-input phone-input" type="text" placeholder="手机号码"
                     v-model="tableData[scope.row.serial].mobile"/>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="邀请码" width="140" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row.serial, scope)" type="text" size="small"
                         v-if="tableData[scope.row.serial].code==''">生成
              </el-button>
              <span class="sc-text"
                    v-if="tableData[scope.row.serial].code">{{ tableData[scope.row.serial].code }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.id == ''" @click="deleteClick(scope.row.serial, tableData,scope)" type="text" size="small">删除
              </el-button>
              <el-button v-else type="text" size="small" style="color: #cedae0">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="add-btn flex flex-col-center flex-row-center" @click="addRowClick">
        <i class="add-img"></i>
        <p class="btn-text">新增一行</p>
      </div>
      <div class="bottom-btn flex flex-col-center flex-row-center">
        <button class="enter-btn" @click="enterClick">完成编辑，进入发送问卷</button>
      </div>
    </div>
    <import-popup :show="isShowImport" title="批量导入" template-path="http://ylpt.lzzcloud.net/phpExcel/zip/wenjuan.xlsx" upload-path="admin/naire/uploadfile"  @close="closeClick" @handleUpload="uploadSuccess"></import-popup>
  </div>
</template>

<script>
import topBar from "./components/top-bar";
import importPopup from "./components/import-popup.vue"
import {company_options, quest_list, save_list} from "../../request/questionApi";

export default {
  components: {
    topBar, importPopup
  },
  data() {
    return {
      tableData: [
        {company: '重庆龙易购科技有限公司', truename: '王小虎', mobile: '18888888888', code: ''},
        {company: '重庆龙易购科技有限公司', truename: '王小虎', mobile: '18888888888', code: ''},
      ],
      visibleTableData: [],
      isShowImport: false,//是否显示导入弹窗
      bluk: '',//
      company_options: []
    }
  },
  mounted() {
    this.$store.commit('SET_QUESTION_STATU', 1);
    let id = this.$route.query.id
    //这里变成真实的pid
    this.$api.quest_list({qid: id}).then(res => {
      this.tableData = res.data
      this.alreadyExist = res.data
      // this.visibleTableData =  res.data
      this.updateVisibleTableData()
      console.log(this.alreadyExist,1)
      console.log(this.visibleTableData,2)
    })

    this.$api.company_options().then(res => {
      this.company_options = res.data.map(item => {
        return {
          ...item,
          value: item.company
        }
      })
    })
  },

  methods: {
    queryCompanySearch(queryString, cb) {
      let company_options = this.company_options;
      let results = queryString ? company_options.filter(this.createFilter(queryString)) : company_options;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
      };
    },
    download(){
      this.fileDownload('http://ylpt.lzzcloud.net/phpExcel/zip/wenjuan.xlsx')
    },
    updateVisibleTableData() {
      this.visibleTableData = this.tableData.map((item, index) => {
        return {
          ...item,
          serial: index
        }
      }).filter(row => row.status !== -1);
    },
    //完成
    enterClick() {
      const qid = this.$route.query.id
      const data = this.tableData.filter(item => item.mobile !== '')
      const paramData = {
        "qid": qid,
        "user": data
      }
      console.log(paramData, 11111)
      if (paramData.user.length>0){
        this.$api.save_list(paramData).then(res => {
          if (!this.visibleTableData.every(item => item.id !== '')) {
            this.$api.quest_list({ qid: qid }).then(res=>{
              this.$store.commit('SET_QUESTION_LIST', res.data);
            })
            // 如果所有id都有值，则执行导航操作
            this.$router.push({ path: '/sendQuestionnaire', query: { qid: qid } });
          } else {
            // 如果有任何一个id为空字符串，则显示警告消息
            this.$message({
              message: '当前企业名单无变化，请先添加企业',
              type: 'warning'
            });
          }

        })
      }else {
        this.$message({
          message: '请先添加需要发送短信的企业名单',
          type: 'warning'
        });
      }
    },
    //批量导入
    importClick() {
      this.isShowImport = true;
    },
    uploadSuccess() {
      this.isShowImport = false;
    },
    //批量导出
    bulkExport() {
      this.$api.bulk_download({qid: this.$route.query.id}).then(res => {
        this.fileDownload(res.data);
        // const link = document.createElement('a');
        // link.style.display = 'none';
        // document.body.appendChild(link);
        // link.href = res.data;
        // link.download = 'filename.xlsx'; // 设置下载文件的名称
        // link.click();
        // document.body.removeChild(link);
      })
    },
    //生成
    handleClick(index, row) {
      let code = Math.floor(1000 + Math.random() * 9000).toString();
      this.tableData[index].code = code
      this.updateVisibleTableData()
      // this.$set(row.row,'code',code);
    },
    //删除一行
    deleteClick(index, rows, row) {
      /*console.log(row,1234567)
      rows.splice(index, 1);
      console.log(row,11)
      row.row.status = '-1'*/
      console.log(index, 11111111111111)
      this.tableData[index].status = -1; // 将状态改为 -1
      this.updateVisibleTableData(); // 更新可见数据
    },
    //新增一行
    addRowClick() {
      // this.visibleTableData.push({company: '',  truename: '', mobile: '',code: '',status:'0',id:''});
      this.tableData.push({company: '', truename: '', mobile: '', code: '', status: 0, id: ''});
      this.updateVisibleTableData()
      console.log(this.alreadyExist,1)
      console.log(this.visibleTableData,2)
    },
    //关闭批量导入弹窗
    closeClick() {
      this.isShowImport = false;
    },
    //刷新验证码
    refreshCode() {
      console.log(this.tableData)
      this.tableData .filter(item => item.id === '').forEach(item => {
        item.code = Math.floor(1000 + Math.random() * 9000).toString();
      });
      this.updateVisibleTableData()
    }
  }
}
</script>

<style lang="scss" scoped>
.send-list-main {
  height: 100%;

  ::v-deep .el-input__inner {
    height: 34px;
    line-height: 34px;
    font-size: 12px;
  }

  ::v-deep  {
    .el-input.is-active .el-input__inner, .el-input__inner:focus, .el-input__inner:hover {
      border-color: #DCDFE6 !important;
    }
  }

  .data-all-content {
    margin: 18px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;

    .sm-content {
      width: 1220px;
      height: 110px;
      background: #F6F7F9;
      border: 1px solid #EDF1F4;
      padding: 20px;
      box-sizing: border-box;

      p {
        font-size: 12px;
        color: #444F57;
        line-height: 26px;
      }

      .color-blue {
        color: #3984F5;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .load-btn-content {
      margin: 20px 0;

      .btn-item {
        height: 34px;
        border-radius: 4px;
        padding: 0 12px;
        margin-right: 10px;

        .btn-img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .btn-text {
          font-size: 12px;
          color: #fff;
        }
      }

      .bg-bule {
        background: #3984F5;

        &:hover {
          cursor: pointer;
          background: #1C72F1;
        }
      }

      .bg-bule-hui {
        background: #609DF7;

        &:hover {
          cursor: pointer;
          background: #3984F5;
        }
      }

      .bg-greend {
        background: #04CD83;

        &:hover {
          cursor: pointer;
          background: #0ABC7B;
        }
      }
    }

    .tab-input {
      height: 34px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #CEDAE0;
      line-height: 34px;
      padding: 0 10px;
      font-size: 12px;
      box-sizing: border-box;
    }

    .company-input {
      width: 380px;
    }

    .name-input {
      width: 170px;
    }

    .phone-input {
      width: 200px;
    }

    .add-btn {
      width: 100px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #3984F5;
      color: #3984F5;
      margin-top: 20px;

      i {
        background: url('~@/assets/sendQuestionnaire/send_list_add_blue.png') no-repeat;
        background-size: contain;
        width: 14px;
        height: 14px;
      }

      .btn-text {
        font-size: 12px;
        margin-left: 5px;
      }

      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #3984F5;

        i {
          background: url('~@/assets/sendQuestionnaire/send_list_add_white.png') no-repeat;
          width: 14px;
          height: 14px;
          background-size: contain;
        }
      }
    }

    .bottom-btn {
      margin-top: 30px;
      width: 1220px;

      .enter-btn {
        width: 240px;
        height: 44px;
        background: #3984F5;
        border-radius: 2px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        line-height: 44px;
      }
    }
  }

}

</style>